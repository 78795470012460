/**
  * Copyright Mackenly Jones. All rights reserved.
  * 
  * Use of this software is governed by the Business Source License
  * included in the LICENSE file.
  * 
  * As of the Change Date specified in that file, in accordance with
  * the Business Source License, use of this software will be governed
  * by the GNU AFFERO GENERAL PUBLIC LICENSE 3.0.
*/


/* Note: Some styles are shared with Updates.css */

.Chat-header {
  grid-row: 1;
  grid-column: 1;
}

.Chat-content {
  grid-row: 2;
  grid-column: 1;
  overflow-y: scroll;
}

.Chat-item img {
  height: 38px;
  border-radius: 30px;
}

#mod-label {
  height: 20px;
  justify-self: center;
  align-self: end;
}

.Chat-item-actions {
  grid-row: 1 / 3;
  grid-column: 10 / 12;
  position: relative;
  justify-self: end;
  align-self: center;
}

.Chat-item-actions button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: var(--content-body-color);
}

.Chat-item-actions img {
  justify-self: center;
  align-self: center;
  height: 25px;
}

.Chat-item-actions-menu {
  display: none;
}

.Chat-item-actions-menu li {
  padding: 10px;
  border-radius: 10px;
}

.Chat-item-actions-menu li:hover {
  background-color: var(--header-bg-color);
}

.Chat-item-actions-menu button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 8vw;
}

.Chat-item-actions-menu button img {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.Chat-item-actions:hover > .Chat-item-actions-menu,
.Chat-item-actions-menu:hover {
  position: absolute;
  display: block;
  right: 5px;
  z-index: 100;
  padding: 10px;
  border-radius: 20px;
  background-color: var(--footer-bg-color);
}

.Chat-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
}

.Chat-empty-container {
  width: calc(30% - 20px);
  padding: 60px 65px;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  text-align: center;
  opacity: 0.6;
}

.Chat-empty img {
  height: 80px;
  width: 80px;
}

.Chat-sender {
  grid-row: 3;
  grid-column: 1;
  padding: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--content-body-color);
  border-radius: 5px;
}

.Chat-sender input {
  width: 100%;
  padding: 10px;
  background-color: transparent;
  border: none;
  color: var(--content-body-color);
}

.Chat-sender img {
  cursor: pointer;
}
