/**
  * Copyright Mackenly Jones. All rights reserved.
  * 
  * Use of this software is governed by the Business Source License
  * included in the LICENSE file.
  * 
  * As of the Change Date specified in that file, in accordance with
  * the Business Source License, use of this software will be governed
  * by the GNU AFFERO GENERAL PUBLIC LICENSE 3.0.
*/

:root {
  --header-text-color: #fff;
  --content-body-color: #f5f7fa;
  --content-link-color: rgb(109, 154, 227);
  --content-disabled-color: #9b9d9e;
  --content-error-color: #f44336;
  --header-bg-color: rgb(5, 14, 26);
  --header-bg-color-opaque: rgba(5, 14, 26, 0.6);
  --main-bg-color: rgb(0, 3, 8);
  --content-body-box-bg: rgb(27, 27, 43);
  --footer-bg-color: rgb(0, 5, 16);
}

.App {
  background-color: var(--main-bg-color);
  color: var(--content-body-color);
}

.App-logo {
  height: 7vmin;
}

.App-header {
  background-color: var(--header-bg-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(8px + 1vmin);
}

.nav-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.NavLink {
  padding-left: 5px;
  padding-right: 20px;
}

.NavLink a {
  text-decoration: none;
  color: var(--header-text-color);
}

.NavLink a:hover, .NavLink button:hover {
  text-decoration: underline;
}

.NavLink button {
  width: fit-content;
  background-color: transparent;
  border: none;
  padding: 0px;
  color: var(--header-text-color);
  font-size: calc(8px + 1vmin);
  font-weight: 100;
  cursor: pointer;
}

.App-body {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  row-gap: 2vh;
  column-gap: 2vw;
  height: 89vh;
  padding: 2vh 1vh 7vh 1vh;
}

.Preview {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  background-color: var(--content-body-box-bg);
  border: 12px solid var(--content-body-box-bg);
  border-radius: 10px;
}

.Updates {
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  padding: 0px;
  background-color: var(--content-body-box-bg);
  border: 12px solid var(--content-body-box-bg);
  border-radius: 10px;
  overflow-y: scroll;
}

.Options {
  grid-row: 1 / 3;
  grid-column: 2 / 3;
  padding: 0px;
  background-color: var(--content-body-box-bg);
  border: 12px solid var(--content-body-box-bg);
  border-radius: 10px;
}

.Chat {
  grid-row: 1 / 3;
  grid-column: 3 / 4;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0fr 1fr auto;
  row-gap: 1vh;
  padding: 0px;
  background-color: var(--content-body-box-bg);
  border: 12px solid var(--content-body-box-bg);
  border-radius: 10px;
}

footer {
  padding-top: 5vh;
  padding-bottom: 1vh;
  text-align: center;
  background-color: var(--footer-bg-color);
}

footer span {
  margin: 0px 2px;
}

a {
  color: var(--content-link-color);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  background-color: var(--content-body-box-bg);
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
