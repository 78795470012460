/**
  * Copyright Mackenly Jones. All rights reserved.
  * 
  * Use of this software is governed by the Business Source License
  * included in the LICENSE file.
  * 
  * As of the Change Date specified in that file, in accordance with
  * the Business Source License, use of this software will be governed
  * by the GNU AFFERO GENERAL PUBLIC LICENSE 3.0.
*/

.Options-outputs {
  display: flex;
}

.Options-tabs {
  display: flex;
  max-width: 25vw;
  overflow-x: scroll;
}

.Options-tab,
.Options-tabs-add {
  font-size: calc(0px + 1vmin);
  padding: 13px 10px 11px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 0.6;
  background-color: var(--header-bg-color);
  border: 0;
  outline: 0;
  border-bottom: 5px solid transparent;
}

.Options-tab:first-of-type {
  border-radius: 10px 0px 0px 10px;
}

.Options-tab-active {
  border-bottom: 5px solid black;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.Options-tab img {
  height: 20px;
  width: 20px;
  margin-right: 8px;
  align-self: center;
}

.srt-header-tab {
  margin: 0px;
  width: 40px !important;
  margin-right: 0px !important;
}

.Options-tab p {
  padding: 0;
  margin: 0;
  align-self: center;
}

.Options-tabs button {
  padding-right: 22px;
  background-color: var(--header-bg-color);
  border: none;
}

.right-rounded {
  border-radius: 0px 10px 10px 0px;
}

.Options-tabs-add {
  background-color: var(--header-bg-color-opaque) !important;
  opacity: 1;
}

.Options-tabs-add img {
  height: 28px;
}

.srt-logo {
  height: 40px;
  width: 40px;
}

.Options-tabs-add p {
  color: var(--content-body-color);
}

.Options-content-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Options-content-header img {
  cursor: pointer;
}

.Options h3 {
  font-size: calc(4px + 1vmin);
}

.Options .Status-indicator {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Options .Status-indicator svg {
  padding-right: 8px;
  padding-top: 2px;
}

.Cloudflare,
.YouTube,
.Facebook,
.Rtmp,
.Srt {
  font-size: calc(4px + 1vmin);
}

.Cloudflare-server-data label,
.Rtmp-server-data label,
.Srt-server-data label {
  display: inline-block;
  margin: 10px 0px;
  font-weight: 700;
}

.Cloudflare-server-data input,
.Rtmp-server-data input,
.Srt-server-data input {
  flex-grow: 1;
  padding: 10px;
  background-color: transparent;
  border: none;
  color: var(--content-disabled-color);
}

.Cloudflare-server-data-server,
.Cloudflare-server-data-key,
.Cloudflare-options-allowed-origin-adder,
.Cloudflare-options-allowed-origin,
.Rtmp-server-data-server,
.Rtmp-server-data-key,
.Srt-server-data-server {
  width: fit-content;
  padding: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid var(--content-body-color);
  border-radius: 5px;
}

.Cloudflare-server-data img,
.Cloudflare-options-allowed-origin-adder img,
.Rtmp-server-data img,
.Srt-server-data img {
  cursor: pointer;
  padding: 0px 5px;
}

.Cloudflare-server-data span,
.Rtmp-server-data span,
.Srt-server-data span {
  opacity: 0;
  padding-right: 5px;
  font-weight: 200;
  font-size: calc(1px + 1vmin);
  transition: opacity 0.5s ease-in-out;
}

.show {
  opacity: 1 !important;
  transition: opacity 0.5s ease-in-out;
}

.Cloudflare-options-allowed-origin {
  border: none;
}

.Options-change-button {
  width: fit-content;
  padding: 8px 20px;
  background-color: var(--content-link-color);
  border: none;
  color: var(--content-body-color);
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
}
