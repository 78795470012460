.Updates h2,
.Chat h2,
.Options h2 {
  margin-top: 8px;
  font-size: calc(6px + 1vmin);
}

.Updates ul,
.Chat ul {
  list-style-type: none;
  padding: 0px;
}

.Updates-item,
.Chat-item {
  display: grid;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: #555 solid 1px;
}

.Updates-item h3,
.Chat-item h3 {
  grid-row: 1;
  grid-column: 2 / 10;
  margin: 0;
  padding-bottom: 2px;
  font-size: calc(4px + 1vmin);
  font-weight: 400;
}

.Updates-item h3 span,
.Chat-item h3 span {
  font-weight: 700;
}

.Updates-item img,
.Chat-item img {
  grid-row: 1 / 3;
  grid-column: 1 / 2;
  margin: 0;
  padding: 0;
  justify-self: center;
  align-self: center;
  height: 20px;
}

.Updates-item p,
.Chat-item p {
  grid-row: 2;
  grid-column: 2 / 10;
  margin: 0;
  font-size: calc(2px + 1vmin);
}
